<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 17:01:13
 * @LastEditTime: 2019-11-13 18:00:29
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="manageLog">
    <page-top :title="$t('restaurant_submenu.65D701@Operation_record')">
      <span class="fa fa-cogs fa-2x"></span>
    </page-top>
    <el-card>
      <section class="justify-between">
        <el-form :inline="true">
          <el-form-item>
            <el-date-picker
              v-model="select.datetime"
              type="daterange"
              range-separator="~"
              :start-placeholder="$t('placeholder.524620@start_time')"
              :end-placeholder="$t('placeholder.ABB7CF@end_time')"
              value-format="timestamp"
              @change="changeDatetime"
               :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="select.username"
              :placeholder="$t('public.A82133@username')"
              prefix-icon="el-icon-search"
              clearable
              @clear="clear"
              @blur="nameQueryFunc"
            ></el-input>
          </el-form-item>
        </el-form>
        <section>
          <KtButton
            :label="$t('public.2C74E6@btn_delete_more')"
            @click="deleLog(false)"
            type="danger"
            :perm="8978"
          ></KtButton>
        </section>
      </section>
      <el-table
        :data="tableData"
        style="width: 100%;"
        :row-class-name="$tableRowClassName"
        v-loading="loading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        @selection-change="handleSelectionChange"
        :header-cell-style="{'text-align':'center',}"
        :cell-style="{'text-align': 'center',}"
      >
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column
          prop="time"
          :label="$t('backend_authrity_operationLog.E3B71C@operation_time')"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="user_name"
          :label="$t('backend_authrity_operationLog.8CF925@operationUser')"
        ></el-table-column>
        <el-table-column
          prop="data"
          :label="$t('backend_authrity_operationLog.AC377D@operation_info')"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="ip" label="IP" show-overflow-tooltip></el-table-column>

        <el-table-column prop="opration" :label="$t('public.03EA09@operation')" width="80">
          <template slot-scope="scope">
            <KtButton
              :label="$t('public.1FBB5E@btn_delete')"
              @click="deleLog(scope.row.id)"
              type="danger"
              :perm="8978"
            ></KtButton>
          </template>
        </el-table-column>
      </el-table>
      <section class="flex-end mt20">
        <el-pagination
          @size-change="handleSizeChange"
          :page-sizes="[5,10,15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </section>
    </el-card>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";
export default {
  data() {
    return {
      select: {
        datetime: "",
        username: ""
      },
      value: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      deleteIds: [],
      total: 0,
      loading: false
    };
  },

  components: {
    pageTop,
    KtButton
  },
  created() {
    this.getLog();
  },
  methods: {
    // 获取列表信息
    getLog() {
      this.loading = true;
      let params = {
        size: this.pageSize,
        page: this.currentPage,
        user_name: this.select.username,
        start_time: this.select.datetime[0] / 1000 || "",
        end_time: this.select.datetime[1] / 1000 || ""
      };
      this.$http.getOperatingList(params).then(res => {
        this.loading = false;
        console.log(res);
        this.tableData = res.data.list;
        this.total = res.data.sum;
      });
    },
    // 删除列表信息
    deleLog(id) {
      this.$confirm(
        this.$t("shop-goods.2ECFD0@Confirm_delete"),
        this.$t("public.553159@tips"),
        {
          confirmButtonText: this.$t("public.3B45A2@confirm"),
          cancelButtonText: this.$t("public.836FD4@cancel")
        }
      ).then(() => {
        this.$http
          .delOperating({
            id_json: id ? JSON.stringify([id]) : JSON.stringify(this.deleteIds)
          })
          .then(res => {
            console.log(res);
            if (this.tableData.length == 1 && this.currentPage > 1) {
              this.currentPage -= 1;
            }
            this.getLog();
          });
      });
    },
    clear() {
      this.getLog();
    },
    // 全选id数组
    handleSelectionChange(val) {
      console.log(val);

      let ids = [];
      val.forEach(i => {
        ids.push(i.id);
      });
      this.deleteIds = ids;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getLog();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getLog();
    },
    nameQueryFunc() {
      console.log(1);

      this.getLog();
    },
    // 时间选择器
    changeDatetime(val) {
      console.log(val);
      this.select.datetime = val || "";
      this.getLog();
    }
  }
};
</script>
<style lang="scss" scoped>
</style>